import React, { Component } from 'react';
import moment from 'moment'

import { Grid } from '@material-ui/core';

import InfiniteScroll from 'react-infinite-scroll-component'

import Calendar from '../../../components/Calendar';
import Filters from '../../../components/common/Filters';
import Modal from '../../../components/common/Modal';
import Button from '../../../components/common/Button';
import UserInfo from '../../../components/common/UserInfo';
import ReservationDetails from '../../../components/common/Reservations/ReservationDetails';

import reservationsService from '../../../services/reservations'
import { CSVLink } from "react-csv";

import { secondsToMinutes, secondsToHours } from '../../../helpers/time'
import './index.scss'
import classnames from 'classnames';

class Operations extends Component {

  state = {
    isOperationInfoShowing: false,
    selectedReservationId: '',
    isLoading: false,
    selectedDay: '',
    operations: [],
    reservations: [],
    lastEvaluatedKey: null,
    order: null,
    statuses: [],
    isReservationsLoading: true,
    types: [],
    reservationsForCsv: []
  }

  componentDidMount() {
    this.fetchReservations(moment())
    this.setState({ selectedDay: moment() })

  }

  loadMoreReservations = async () => {
    const { selectedDay, reservations, lastEvaluatedKey, order, statuses, types } = this.state

    const data = await reservationsService.getDayReservations(moment(selectedDay).format('YYYY-MM-DD'), { lastEvaluatedKey, order, statuses, types })

    if (data.status === 'ok') {
      this.setState({ reservations: [...reservations, ...data.result.reservations], lastEvaluatedKey: data.result.last_evaluated_key })

    } else {
      console.warn(data.error)
    }

    this.setState({ isLoading: false });
  }

  fetchReservations = async (day) => {
    const { order, statuses, types } = this.state
    try {
      const { result } = await reservationsService.getDayReservations(moment(day).format('YYYY-MM-DD'), { order, statuses, types })
      this.setState({ reservations: result.reservations, lastEvaluatedKey: result.last_evaluated_key, isLoading: false, isReservationsLoading: false })

    } catch (error) {
      console.log(error)
    }
  }

  onUserSettingsClick = id => async e => {
    e.stopPropagation();
    this.setState({ selectedReservationId: id, isOperationInfoShowing: true });
  }

  closeUserInfo = () => new Promise(resolve =>
    this.setState({ isOperationInfoShowing: false }, () => resolve())
  );


  handleDayClick = (day) => {
    const { selectedDay } = this.state

    if (moment(selectedDay).format('DD-MM-YYYY') === moment(day).format('DD-MM-YYYY')) {
      return false
    }
    this.setState({ selectedDay: day, statuses: [], types: [], order: null, isLoading: true, isReservationsLoading: true }, () => this.fetchReservations(day))

  };

  getAllReservations = async () => {
    const { selectedDay, order, statuses, types } = this.state

    const data = await reservationsService.getDayReservations(moment(selectedDay).format('YYYY-MM-DD'), { pageSize: 10000000, order, statuses, types })

    if (data.status === 'ok') {
      const allReservations = data.result.reservations.map(r => {
        r.user = r.user && `${r.user.first_name} ${r.user.last_name}`
        r.notifications = r.notifications.length > 0 && r.notifications.map(n => `${n.body}\n`)
        return r
      })
      this.setState({ reservationsForCsv: allReservations })
    } else {
      console.warn(data.error)
    }
    let btn = this.refs.csv;
    btn.link.click();
  }

  onStatusChange = async (statuses) => {
    const { selectedDay } = this.state

    this.setState({ isReservationsLoading: true, statuses }, () => this.fetchReservations(selectedDay))
  }

  onTimeChange = async (item) => {
    const { selectedDay } = this.state

    this.setState({ isReservationsLoading: true, order: item }, () => this.fetchReservations(selectedDay))
  }

  onTypeChange = async (types) => {
    const { selectedDay } = this.state

    this.setState({ isReservationsLoading: true, types }, () => this.fetchReservations(selectedDay))
  }

  render() {
    const {
      onTimeChange,
      getAllReservations,
      closeUserInfo,
      onUserSettingsClick,
      handleDayClick,
      loadMoreReservations,
      onTypeChange,
      onStatusChange,
      state: {
        reservations,
        reservationsForCsv,
        lastEvaluatedKey,
        isOperationInfoShowing,
        selectedReservationId,
        selectedDay,
        isLoading
      }
    } = this;
    return (
      <div>
        {
          isOperationInfoShowing && (
            <Modal className={classnames({ "operations-modal": true, "thin": reservations.find(r => r.res_id === selectedReservationId).user === null })} onClose={closeUserInfo}>

              <div className={classnames({ "operation-info-container": true, "without-user": reservations.find(r => r.res_id === selectedReservationId).user === null })}>
                <ReservationDetails className="operation-reservation-details" reservation={reservations.find(r => r.res_id === selectedReservationId)} onClose={closeUserInfo} />
                {
                  reservations.find(r => r.res_id === selectedReservationId).user !== null &&
                  (<UserInfo
                    className="operation-user-info"
                    user={reservations.find(r => r.res_id === selectedReservationId).user}
                    close={closeUserInfo}
                    withEdit={false}
                    withRemove={false}
                  />
                  )
                }
              </div>
            </Modal>
          )
        }

        <Grid container spacing={3} className="operations-container">

          <Grid item md={12} lg={10}>
            <Calendar className="operations-calendar" handleDayClick={handleDayClick} selectedDay={selectedDay} />
          </Grid>

          <Grid container item md={12} lg={2} justify="flex-end" style={{ height: '50px' }}>

            <Button invert onClick={getAllReservations}>DOWNLOAD</Button>
            <CSVLink
              filename="operations.csv"
              data={reservationsForCsv}
              ref="csv"
              style={{ display: 'none' }}
            >
            </CSVLink>
          </Grid>

          <Grid item xs={12} sm={12} container justify="flex-end">
            <Filters
              onTimeChange={onTimeChange}
              onTypeChange={onTypeChange}
              onStatusChange={onStatusChange}
              withBookingTimes={true} />
          </Grid>

          <Grid item xs={12} sm={12} xl={12}>
            <InfiniteScroll
              dataLength={reservations.length}
              next={loadMoreReservations}
              height={'60vh'}
              hasMore={lastEvaluatedKey !== null}

            >
              <table>
                <tbody>
                  <tr>
                    <th>date</th>
                    <th>time</th>
                    <th>type</th>
                    <th>duration</th>
                    <th>t&g</th>
                    <th>status</th>
                    <th>registration</th>
                    <th>timestamp</th>
                    <th>name</th>
                    <th>company</th>
                  </tr>
                </tbody>

                {isLoading ? <tbody><tr><td>Loading...</td></tr></tbody> :
                  <tbody>
                    {reservations.map(val => val.status !== 'canceled' && (
                      <tr key={val.res_id} className="table-data" onClick={onUserSettingsClick(val.res_id)}>
                        <td>{moment(val.eta_dt).format('DD-MM-YYYY') || '-'}</td>
                        <td>{moment(val.eta_dt).utc().format('kk:mm') || '-'}</td>
                        <td>{val.operation_type || '-'}</td>
                        <td>{val.operation_type === 'Maintenance' ? `${secondsToHours(val.expected_duration)} ${val.expected_duration > 1 ? 'hours' : 'hour'}` : `${secondsToMinutes(val.expected_duration)} min` || ''}</td>
                        <td>{val.nr_repeats || ''}</td>
                        <td>{val.status || '-'}</td>
                        <td>{val.aircraft_registration || '-'}</td>
                        <td>{moment(val.created_datetime).utc().format('dddd MMMM Do YYYY, kk:mm') || '-'}</td>
                        <td>{val.user && val.user.first_name} {val.user && val.user.last_name}</td>
                        <td>{(val.user && val.user.company_name) || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                }
              </table>
            </InfiniteScroll>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Operations;
